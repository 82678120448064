export const SNOWPACK_PUBLIC_ENVIRONMENT = "production";
export const SNOWPACK_PUBLIC_API_URL = "https://pim.crystallize.com";
export const SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API = "https://subscription-service.crystallize.com/api/graphql";
export const SNOWPACK_PUBLIC_PIM_VERSION = "fa7a0d0a33106874e58be0ca578fe328ac2e4aea";
export const SNOWPACK_PUBLIC_GOOGLE_CLOUD_API = "AIzaSyAkRV-Xe_go6w9_7QuNi8ZGXreb78WnZg8";
export const SNOWPACK_PUBLIC_CLIENT_CATALOGUE_URL = "https://api.crystallize.com/:tenantIdentifier/catalogue";
export const SNOWPACK_PUBLIC_STRIPE_PUBLISHABLE_KEY = "pk_live_JRx4qlC2s1G13IL4X9R8Qei800iZb4khmO";
export const SNOWPACK_PUBLIC_CLIENT_SEARCH_URL = "https://api.crystallize.com/:tenantIdentifier/search";
export const MODE = "development";
export const NODE_ENV = "development";
export const SSR = false;