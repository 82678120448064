import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

// Public stuff
const CLIENT_CATALOGUE_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CLIENT_CATALOGUE_URL || 'https://api-dev.crystallize.digital/:tenantIdentifier/catalogue';
const CLIENT_SEARCH_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CLIENT_SEARCH_URL || 'https://api-dev.crystallize.digital/:tenantIdentifier/search';
const API_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL || 'https://pim-dev.crystallize.digital';
const GOOGLE_CLOUD_API = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_CLOUD_API || 'AIzaSyDpkkRla7AV5DLBhFrnAevJNGJLMDVZpA8';
const ENVIRONMENT = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENVIRONMENT || 'development'; // When running the API locally

const LOCAL_BACKEND = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_LOCAL_BACKEND === 'true';
const CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API || 'https://dev-subscription-service.crystallize.com/api/graphql';
const STRIPE_PUBLISHABLE_KEY = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_STRIPE_PUBLISHABLE_KEY || 'pk_test_xgCvtfbG5zXkKrKcOUIhqxNH00rYijpklm';
export default {
  ENVIRONMENT,
  API_URL,
  SIGNIN_URL: `${API_URL}/signin`,
  SIGN_OUT_URL: `${API_URL}/signout`,
  GRAPH_URL: LOCAL_BACKEND ? API_URL : `${API_URL}/graphql`,
  CLIENT_CATALOGUE_URL,
  CLIENT_SEARCH_URL,
  TENANT_OVERRIDE: window.__tenant_override,
  ACCESS_TOKEN_ID: window.__access_token_id,
  ACCESS_TOKEN_SECRET: window.__access_token_secret,
  GOOGLE_CLOUD_API,
  CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API,
  STRIPE_PUBLISHABLE_KEY
};